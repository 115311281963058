import React, { useState } from "react";
import { Link } from "gatsby";

export default () => {
  const [open, setOpen] = useState(false);

  return (
    <div
      id="fs-side-bar"
      onMouseOver={() => setOpen(true)}
      onMouseOut={() => setOpen(false)}
      className={(open && "open") || "closed"}
    >
      <div id="fs-side-bar-right">
        <img
          id="side-bar-burger"
          alt=""
          src={(open && "/img/arrow.svg") || "/img/burger.svg"}
        ></img>
      </div>
      <div id="fs-side-bar-center">
        <p>Frickly</p>
        <p>Systems</p>
        <p>Über uns</p>
        <p>Blog</p>
        <p>Kontakt</p>
      </div>
      <div id="fs-side-bar-bottom">
        <Link to="/impressum">Impressum</Link>
      </div>
    </div>
  );
};
