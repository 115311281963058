import React from "react";

export default () => (
  <div id="fs-contact">
    <form>
      <div className="row">
        <input type="text" placeholder="Your Name *"></input>
      </div>
      <div className="row">
        <input type="text" placeholder="Email Address *"></input>
        <input type="text" placeholder="Website"></input>
      </div>
      <div className="row">
        <input type="text" placeholder="Phone Number"></input>
        <input type="text" placeholder="Business"></input>
      </div>
      <div className="row">
        <textarea placeholder="Message *"></textarea>
      </div>
    </form>
  </div>
);
