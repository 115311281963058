import React, { useRef, useState, useEffect } from "react";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

export default ({ children, images = [] }) => {
  const images1 = images.filter((_, index) => index < 3);
  const images2 = images.filter((_, index) => index >= 3 && index < 6);

  const wrapperRef = useRef(null);

  const [wrapperHeight, setWrapperHeight] = useState(0);

  useEffect(() => {
    if (wrapperRef.current) {
      setTimeout(() => {
        setWrapperHeight(wrapperRef.current.clientHeight);
      }, 1);
    }
  }, [wrapperRef]);

  const galleryRef = useRef(null);

  const [galleryHeight, setGalleryHeight] = useState(0);

  useEffect(() => {
    if (galleryRef.current) {
      setTimeout(() => {
        setGalleryHeight(galleryRef.current.clientHeight);
      }, 1);
    }
  }, [galleryRef]);

  const [animationProgress, setAnimationProgress] = useState(0);

  useScrollPosition(
    ({ currPos }) => {
      const y = -currPos.y;
      if (y <= 0) {
        setAnimationProgress(0);
        return;
      }

      if (y >= wrapperHeight - galleryHeight) {
        setAnimationProgress(1);
        return;
      }

      setAnimationProgress(y / (wrapperHeight - galleryHeight));
    },
    [galleryHeight, wrapperHeight],
    wrapperRef
  );

  const base = 20;

  const imagePadding = (1 - animationProgress) * base;

  const imageStyle = {
    padding: imagePadding,
  };

  const galleryPadding = 2 * (base - imagePadding);

  const galleryStyle = {
    padding: galleryPadding,
  };

  const galleryTextStyle = {
    opacity: animationProgress < 0.8 ? 0 : (animationProgress - 0.8) * 5,
  };

  const blur = animationProgress > 0.8 ? (animationProgress - 0.8) * 5 : 0;

  return (
    <div id="fs-gallery-wrapper" ref={wrapperRef}>
      <div id="fs-gallery" ref={galleryRef} style={galleryStyle}>
        <div style={{ filter: `blur(${blur}rem)` }}>
          <div id="fs-gallery-container">
            {images1.map((src, index) => (
              <div class="fs-gallery-image-container">
                <img alt="" src={src} key={index} style={imageStyle}></img>
              </div>
            ))}
          </div>
          <div id="fs-gallery-container">
            {images2.map((src, index) => (
              <div class="fs-gallery-image-container">
                <img alt="" src={src} key={index} style={imageStyle}></img>
              </div>
            ))}
          </div>
        </div>
        <div id="fs-gallery-text" style={galleryTextStyle}>
          {children}
        </div>
      </div>
    </div>
  );
};
