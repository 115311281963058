import React, { useRef, useMemo, useEffect, useState } from "react";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

const ThingyListElement = ({
  title,
  content,
  onScrolledOverChange,
  active,
}) => {
  const ref = useRef(null);

  const [scrolledOver, setScrolledOver] = useState(false);

  useScrollPosition(
    ({ currPos }) => {
      setScrolledOver(currPos.y < 0);
    },
    [],
    ref
  );

  useEffect(() => {
    onScrolledOverChange(scrolledOver);
  }, [onScrolledOverChange, scrolledOver]);

  // be active if undefined to calculate height
  const expanded = !ref.current || active || scrolledOver;

  const classes =
    ((active && "active ") || " ") + ((expanded && "expanded") || " ");

  return useMemo(() => (
    <li ref={ref} className={"fs-thingy-list-element " + classes}>
      <h3>{title}</h3>
      <p className="fs-thingy-list-element-content">{content}</p>
    </li>
  ));
};

const Background = ({ images, activeIndex }) => {
  return (
    <div id="fs-slide-thingy-background-container">
      <div id="fs-slide-thingy-background">
        {images.map((image, index) => (
          <div
            key={index}
            className={
              "fs-slide-thingy-background-image" +
              (index <= activeIndex ? " active" : "")
            }
            style={{ backgroundImage: `url("${image}")` }}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default ({ elements = [] }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div id="fs-slide-thingy">
      <ul id="fs-slide-thingy-text">
        {elements.map((data, index) => (
          <ThingyListElement
            {...data}
            key={index}
            active={index === activeIndex}
            onScrolledOverChange={(scrolledOver) => {
              if (scrolledOver) {
                setActiveIndex(index + 1);
              } else if (activeIndex > index) {
                setActiveIndex(index);
              }
            }}
          ></ThingyListElement>
        ))}
      </ul>
      <Background
        images={elements.map((d) => d.image)}
        activeIndex={activeIndex}
      ></Background>
    </div>
  );
};
