import React, { Component } from "react";
import Helmet from "react-helmet";
import "../../assets/sass/new-styles.sass";
import config from "../../../config";
import SideBar from "../SideBar";
import Footer from "../Footer";

export default ({ children }) => (
  <React.Fragment>
    <Helmet>
      <title>{config.siteTitle}</title>
      <meta name="description" content={config.siteDescription} />
    </Helmet>
    <SideBar></SideBar>
    <div id="layout-wrapper">
      <div id="content-wrapper">{children}</div>
      <Footer />
    </div>
  </React.Fragment>
);
