import React from "react";

import Layout from "../components/NewLayout";
import Title from "../components/Title";
import Gallery from "../components/Gallery";
import ImageMontage from "../components/ImageMontage";
import SlideThingy from "../components/SlideThingy";
import Contact from "../components/Contact";

const galleryImage = [
  "/img/gallery/1.png",
  "/img/gallery/2.png",
  "/img/gallery/3.png",
  "/img/gallery/4.png",
  "/img/gallery/5.png",
  "/img/gallery/6.png",
];

const dummyText =
  "Foo bar foo bar Foo bar foo bar Foo bar foo bar Foo bar foo bar Foo bar foo bar Foo bar foo bar Foo bar foo bar Foo bar foo bar ";

const points = [
  {
    title: "Point 1",
    content: dummyText,
    image: "/img/slide-thingy/background-1.png",
  },
  {
    title: "Point 2",
    content: dummyText,
    image: "/img/slide-thingy/background-2.png",
  },
  {
    title: "Point 3",
    content: dummyText,
    image: "/img/slide-thingy/background-3.png",
  },
  {
    title: "Point 4",
    content: dummyText,
    image: "/img/slide-thingy/background-1.png",
  },
  {
    title: "Point 5",
    content: dummyText,
    image: "/img/slide-thingy/background-2.png",
  },
  {
    title: "Point 6",
    content: dummyText,
    image: "/img/slide-thingy/background-3.png",
  },
];

export default () => (
  <Layout>
    <Title></Title>
    <Gallery images={galleryImage}>
      <h1>Title</h1>
      Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
      eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
      voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
      clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
      amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
      nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed
      diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
      Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor
      sit amet.
    </Gallery>
    <ImageMontage></ImageMontage>
    <SlideThingy elements={points}></SlideThingy>
    <Contact></Contact>
  </Layout>
);
